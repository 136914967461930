
import { defineComponent, ref, unref, toRefs, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getAdmin } from '@/services/api';

import { PartialAdmin } from '@/interfaces/Admin';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  setup() {
    const adminId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const data = ref<PartialAdmin>({});
    const roles = ref();
    const { t } = useI18n();


    const getRoles = () => {
      roles.value = data.value.roles.map(({ name }) => name);
    };

    onMounted(async() => {
      const res = await getAdmin({ adminId });
      data.value = res.data;
      getRoles();
    });

    return {
      data,
      roles,
      t,
      page
    };
  }
});
